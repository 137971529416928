import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Veiculos.css';
import { valor_inicial } from './utils';

function EditarVeiculos() {
  const navigate = useNavigate();
  const [veiculos, setVeiculos] = useState(valor_inicial);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetVeiculos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditVeiculos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setVeiculos(data.Veiculos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetVeiculos();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditVeiculos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={false}
          initialValues={veiculos}
          validationSchema={yup.object({
            NomeProprietario: yup
              .string()
              .required('O campo é obrigatório.')
              .nullable(),
            // NomeVeiculo: yup.string().required('O campo é obrigatório.').nullable(),
            // Empresa: yup.string().required('O campo é obrigatório.').nullable(),
            // Condutor: yup.string().required('O campo é obrigatório.').nullable(),
            // Fabricante: yup.string().required('O campo é obrigatório.').nullable(),
            // AnoFabricacao: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
            // Placa: yup.string().required('O campo é obrigatório.').nullable(),
            // Combustivel: yup.string().required('O campo é obrigatório.').nullable(),
            // Cor: yup.string().required('O campo é obrigatório.').nullable(),
            // Antt: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
            // Observacoes: yup.string().required('O campo é obrigatório.').nullable(),
          })}
          onSubmit={async values => {
            setVeiculos(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();
  let titulo = `${formik.values.Id !== '' ? 'Editar' : 'Novo'} Veículo`;

  const optionsCombustivel = [
    'Gasolina',
    'Etanol',
    'Diesel',
    'Gás Natural Veicular (GNV)',
  ];
  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Veiculos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Veículos', path: '/Veiculos/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Proprietário"
          id="NomeProprietario"
          name="NomeProprietario"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Pessoas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Nome do Veículo"
          id="NomeVeiculo"
          name="NomeVeiculo"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Condutor"
          id="Condutor"
          name="Condutor"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Pessoas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Fabricante"
          id="Fabricante"
          name="Fabricante"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Ano de Fabricação"
          id="AnoFabricacao"
          name="AnoFabricacao"
          component={InputNumInt}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Placa"
          id="Placa"
          name="Placa"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Combustível"
          id="Combustivel"
          name="Combustivel"
          component={InputSelect}
          gridColumn={gridColumn.gc3}
          options={optionsCombustivel}
        />
        <InputFormik
          titulo="Cor"
          id="Cor"
          name="Cor"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Registro ANTT"
          id="Antt"
          name="Antt"
          component={InputNumInt}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTextArea}
          gridColumn={gridColumn.gc3}
        />
      </Card>
    </>
  );
}

export default EditarVeiculos;
