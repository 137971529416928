import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import {
  addIDtoURL,
  isNotNullOrEmpty,
  optionsPaises,
  optionsUFs,
} from '../../utils/utils';
import './Clientes.css';
import {
  getButtonEditarText,
  getValorInicial,
  optionsDiaMes,
  optionsIndicadorIE,
  optionsTipoComissao,
} from './utils';
import InputDinheiro from './../../components/Inputs/InputDinheiro/InputDinheiro';
import IconeDownload from '../../components/Icones/IconeDownload/IconeDownload';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import InputArquivos from '../../components/Inputs/InputArquivo/InputArquivos';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import { baixarArquivoURL } from '../../utils/download';

function EditarClientes({ categoria }) {
  const navigate = useNavigate();
  const [cliente, setCliente] = useState(getValorInicial(categoria));
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [contUpload, setContUpload] = useState(0);
  const [message, setMessage] = useState('');

  let { id } = useParams();
  let upload = true;
  useEffect(() => {
    async function GetClientes() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditClientes/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Cliente.Categoria = categoria;
        setCliente(data.Cliente);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetClientes();
  }, [id, categoria, alertTitle]);

  let save = async values => {
    setLoading(true);
    let pedido = values;
    //Nome município
    var municipio = document.getElementById('CodigoCidadeEndereco').options[
      document.getElementById('CodigoCidadeEndereco').selectedIndex
    ].text;
    if (isNotNullOrEmpty(municipio)) {
      pedido.CidadeEndereco = municipio.trim();
    }
    //Nome UF
    var estado =
      document.getElementById('CodigoUFEndereco').options[
        document.getElementById('CodigoUFEndereco').selectedIndex
      ].text;
    if (isNotNullOrEmpty(estado)) {
      pedido.UFEndereco = estado.trim();
    }
    //Nome Pais
    var pais =
      document.getElementById('CodigoPaisEndereco').options[
        document.getElementById('CodigoPaisEndereco').selectedIndex
      ].text;
    if (isNotNullOrEmpty(estado)) {
      pedido.PaisEndereco = pais.trim();
    }

    let payload = {
      data: JSON.stringify(pedido, null, 2),
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditClientes/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const uploadArquivos = async event => {
    const files = event.target.files;
    setContUpload(0);
    Array.from(files).forEach(async file => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        setContUpload(prevContUpload => prevContUpload + 1);
        let res = await api.post(
          `EditClientes/UploadFile?id=${
            cliente.Id
          }&database=${localStorage.getItem('database')}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        let data = res.data;
        setContUpload(prevContUpload => prevContUpload - 1);

        if (data.Success && contUpload <= 0) {
          setAlertStatus('success');
          setAlertTitle('Arquivos carregados com sucesso');
          onOpen();
          upload = !upload;
        } else if (!data.Success) {
          setAlertStatus('error');
          setAlertTitle(data.MSG);
          onOpen();
        }
      } catch (error) {
        setContUpload(prevContUpload => prevContUpload - 1);
      }
    });

    return;
  };

  const baixarArquivo = async (empresaId, nomeArquivo) => {
    let database = localStorage.getItem('database');
    const linkSource = `${database}/funcionarios/${empresaId}/${nomeArquivo}`;

    let payload = {
      database: localStorage.getItem('database'),
      data: linkSource,
    };
    baixarArquivoURL('/EditClientes/GetUrlDownload', payload, nomeArquivo);
  };

  const deleteItem = async cliente => {
    let database = localStorage.getItem('database');

    let payload = {
      database: database,
      data: cliente.Id,
      arg: selectedItem,
    };
    let res = await api.post('/EditClientes/DeleteFile', payload);
    let data = res.data;
    onCloseExcluir();

    setCliente(prevCliente => {
      return {
        ...prevCliente,
        Arquivos: prevCliente.Arquivos.filter(q => q !== selectedItem),
      };
    });

    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  function handleDelete(item) {
    setSelectedItem(item);
    setAlertTitle('Excluir');
    setMessage(`Deseja excluir o arquivo ${item}?`);
    onOpenExcluir();
  }

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <AlertaConfirmacao
        isOpen={isOpenExcluir}
        onClose={onCloseExcluir}
        description={message}
        title={alertTitle}
        onConfirm={() => deleteItem(cliente)}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={cliente}
          // validationSchema: yup.object({
          //   NomeFantasia: yup.string().required('O campo é obrigatório.').nullable(),
          //   RazaoSocial: yup.string().required('O campo é obrigatório.').nullable(),
          //   CPF_CNPJ: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
          //   EhFisica: yup.boolean().required('O campo é obrigatório.').nullable(),
          //   IndicadorIE: yup.string().required('O campo é obrigatório.').nullable(),
          //   IE: yup.string().required('O campo é obrigatório.').nullable(),
          //   IM: yup.string().required('O campo é obrigatório.').nullable(),
          //   CEPEndereco: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
          //   RuaEndereco: yup.string().required('O campo é obrigatório.').nullable(),
          //   NumeroEndereco: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
          //   ComplementoEndereco: yup.string().required('O campo é obrigatório.').nullable(),
          //   BairroEndereco: yup.string().required('O campo é obrigatório.').nullable(),
          //   CidadeEndereco: yup.string().required('O campo é obrigatório.').nullable(),
          //   CodigoUFEndereco: yup.string().required('O campo é obrigatório.').nullable(),
          //   TelefoneComercial: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
          //   Email: yup.string().email('Informe um endereço de email válido').required('O campo é obrigatório.').nullable(),
          // }),
          onSubmit={async values => {
            setCliente(values);
            await save(values);
          }}
        >
          <Interno
            navigate={navigate}
            categoria={categoria}
            uploadArquivos={uploadArquivos}
            baixarArquivo={baixarArquivo}
            handleDelete={handleDelete}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({
  navigate,
  categoria,
  uploadArquivos,
  baixarArquivo,
  handleDelete,
}) {
  const { values, touched, setFieldValue } = useFormikContext();
  const [optionsCidade, setOptionsCidade] = useState([]);

  useEffect(() => {
    const fetchCidades = async () => {
      try {
        const response = await fetch(
          `https://erpsuite-ibge.s3.us-east-2.amazonaws.com/${values.CodigoUFEndereco}.json`
        );
        const json = await response.json();
        const cidadesData = json.map(cidade => ({
          name: cidade.nome,
          value: cidade.id,
        }));
        setOptionsCidade(cidadesData);
      } catch (error) {
        console.error('Error fetching cidades:', error);
      }
    };

    fetchCidades();
  }, [values.CodigoUFEndereco]);

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate(`/${categoria}/Listar`),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: categoria, path: `/${categoria}/Listar` },
          { nome: getButtonEditarText(categoria, values.Id) },
        ]}
      />

      <Card titulo={'CADASTRO DE ' + categoria.toUpperCase()}>
        {categoria !== 'Funcionarios' && (
          <InputFormik
            titulo="Pessoa Física?"
            id="EhFisica"
            name="EhFisica"
            component={InputToggle}
            gridColumn={gridColumn.gc4}
          />
        )}
        <InputFormik
          titulo={values.EhFisica ? 'Nome' : 'Nome Fantasia'}
          id="NomeFantasia"
          name="NomeFantasia"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        {!values.EhFisica && (
          <InputFormik
            titulo={'Razão Social'}
            id="RazaoSocial"
            name="RazaoSocial"
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
        )}
        <InputFormik
          titulo={values.EhFisica ? 'CPF' : 'CNPJ'}
          id="CPF_CNPJ"
          name="CPF_CNPJ"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      {values.Categoria !== 'Funcionarios' &&
      values.Categoria !== 'Vendedores' ? (
        <Card titulo="INFORMAÇÕES FISCAIS">
          <InputFormik
            titulo="Indicador da IE"
            id="indicadorIE"
            name="indicadorIE"
            component={InputSelect}
            options={optionsIndicadorIE}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Inscrição Estadual"
            id="IE"
            name="IE"
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Inscrição Municipal"
            id="IM"
            name="IM"
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
        </Card>
      ) : null}
      {values.Categoria === 'Funcionarios' && (
        <>
          <Card titulo="DETALHES FUNCIONÁRIO">
            <InputFormik
              titulo="Data de Admissão"
              id="DataAdmissao"
              name="DataAdmissao"
              component={InputData}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Data de Desligamento"
              id="DataDesligamento"
              name="DataDesligamento"
              component={InputData}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Salário"
              id="Salario"
              name="Salario"
              component={InputDinheiro}
              gridColumn={gridColumn.gc4}
            />
          </Card>
          <Card titulo="ARQUIVOS">
            <Box p={2} gridColumn={gridColumn.gc12}>
              <InputArquivos handleUpload={uploadArquivos} />
            </Box>

            <Box className="arquivosCadastrados" gridColumn={gridColumn.gc12}>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>NOME</Th>
                      <Th>BAIXAR</Th>
                      <Th>
                        <IconeDeletar />
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {values.Arquivos.map((arquivo, index) => (
                      <Tr key={index}>
                        <Td>{arquivo}</Td>
                        <Td>
                          <IconeDownload
                            onClick={() => baixarArquivo(values.Id, arquivo)}
                          />
                        </Td>
                        <Td>
                          <IconeDeletar onClick={() => handleDelete(arquivo)} />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        </>
      )}
      {values.Categoria === 'Vendedores' && (
        <Card titulo="DETALHES VENDEDOR">
          <InputFormik
            titulo="Empresa"
            id="Empresa"
            name="Vendedor.Empresa"
            component={InputAutoComplete}
            gridColumn={gridColumn.gc4}
            path="Empresa"
            autocomplete={true}
            database={localStorage.getItem('database')}
          />
          <InputFormik
            titulo="Tipo de Comissão"
            id="Vendedor.TipoComissao"
            name="Vendedor.TipoComissao"
            component={InputSelect}
            options={optionsTipoComissao}
            gridColumn={gridColumn.gc4}
          />
          {values.Vendedor.TipoComissao === '0' ? (
            <InputFormik
              titulo="Percentual"
              id="Vendedor.Percentual"
              name="Vendedor.Percentual"
              component={InputFloat}
              gridColumn={gridColumn.gc4}
            />
          ) : (
            <InputFormik
              titulo="Valor"
              id="Vendedor.Valor"
              name="Vendedor.Valor"
              component={InputFloat}
              gridColumn={gridColumn.gc4}
            />
          )}
          <InputFormik
            titulo="Dia da Comissão"
            id="Vendedor.DiaComissao"
            name="Vendedor.DiaComissao"
            component={InputSelect}
            options={optionsDiaMes}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Tipo de Comissão PDV"
            id="Vendedor.TipoComissaoPDV"
            name="Vendedor.TipoComissaoPDV"
            component={InputSelect}
            options={optionsTipoComissao}
            gridColumn={gridColumn.gc4}
          />
          {values.Vendedor.TipoComissaoPDV === '0' ? (
            <InputFormik
              titulo="Percentual"
              id="Vendedor.PercentualPDV"
              name="Vendedor.PercentualPDV"
              component={InputFloat}
              gridColumn={gridColumn.gc4}
            />
          ) : (
            <InputFormik
              titulo="Valor"
              id="Vendedor.ValorPDV"
              name="Vendedor.ValorPDV"
              component={InputFloat}
              gridColumn={gridColumn.gc4}
            />
          )}
          <InputFormik
            titulo="Dia da Comissão PDV"
            id="Vendedor.DiaComissaoPDV"
            name="Vendedor.DiaComissaoPDV"
            component={InputSelect}
            options={optionsDiaMes}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Meta diária"
            id="Vendedor.MetaDiaria"
            name="Vendedor.MetaDiaria"
            component={InputFloat}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Meta mensal"
            id="Vendedor.MetaMensal"
            name="Vendedor.MetaMensal"
            component={InputFloat}
            gridColumn={gridColumn.gc4}
          />
        </Card>
      )}
      {values.Categoria === 'Clientes' && (
        <Card titulo="DETALHES CLIENTE">
          <InputFormik
            titulo="Data Nascimento"
            id="DataNascimento"
            name="DataNascimento"
            component={InputData}
            gridColumn={gridColumn.gc4}
          />
          <InputFormik
            titulo="Condição Pgto"
            id="CondicaoPagamento"
            name="CondicaoPagamento"
            component={InputAutoComplete}
            gridColumn={gridColumn.gc4}
            path="CondicoesPagamento"
            autocomplete={true}
            database={localStorage.getItem('database')}
          />
          <InputFormik
            titulo="Tabela Preços"
            id="TabelaPreco"
            name="TabelaPreco"
            component={InputAutoComplete}
            gridColumn={gridColumn.gc4}
            path="TabelasPreco"
            autocomplete={true}
            database={localStorage.getItem('database')}
          />
          <InputFormik
            titulo="Categoria Venda"
            id="CategoriaVenda"
            name="CategoriaVenda"
            component={InputAutoComplete}
            gridColumn={gridColumn.gc4}
            path="CategoriaVenda"
            autocomplete={true}
            database={localStorage.getItem('database')}
          />
        </Card>
      )}

      <Card titulo="ENDEREÇO">
        <InputFormik
          titulo="CEP"
          id="CEPEndereco"
          name="CEPEndereco"
          component={InputTexto}
          gridColumn={gridColumn.gc2}
        />
        <InputFormik
          titulo="Rua"
          id="RuaEndereco"
          name="RuaEndereco"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Número"
          id="NumeroEndereco"
          name="NumeroEndereco"
          component={InputTexto}
          gridColumn={gridColumn.gc2}
        />
        <InputFormik
          titulo="Complemento"
          id="ComplementoEndereco"
          name="ComplementoEndereco"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Bairro"
          id="BairroEndereco"
          name="BairroEndereco"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Cidade"
          id="CodigoCidadeEndereco"
          name="CodigoCidadeEndereco"
          component={InputSelect}
          options={optionsCidade}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Estado"
          id="CodigoUFEndereco"
          name="CodigoUFEndereco"
          component={InputSelect}
          options={optionsUFs}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="País"
          id="CodigoPaisEndereco"
          name="CodigoPaisEndereco"
          component={InputSelect}
          options={optionsPaises}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo="INFORMAÇÕES DE CONTATO">
        <InputFormik
          titulo="Telefone Comercial"
          id="TelefoneComercial"
          name="TelefoneComercial"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Telefone"
          id="Telefone"
          name="Telefone"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Site"
          id="Site"
          name="Site"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Email"
          id="Email"
          name="Email"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Email Comercial"
          id="EmailComercial"
          name="EmailComercial"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Email Financeiro"
          id="EmailFinanceiro"
          name="EmailFinanceiro"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Contato"
          id="Contato"
          name="Contato"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Contato 2"
          id="Contato2"
          name="Contato2"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Contato 3"
          id="Contato3"
          name="Contato3"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarClientes;
