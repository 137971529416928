import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioDRE } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import { ajustaDatas } from '../../utils/utils';
import InputNumInt from './../../components/Inputs/InputNumInt/InputNumInt';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { formataDinheiro } from '../../utils/formatador';

function RelDRE() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);
  const [relDRE, setRelDRE] = useState([]);

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  async function GetRelDRE(filtros) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/DREReport/GetList', payload);
    let data = res.data;
    console.log(data);
    if (res.status === 200) {
      setRelDRE(data.Grupos);
      console.log(data.Grupos);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelDRE(getFiltroPadraoRelatorioDRE());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelDRE(getFiltroPadraoRelatorioDRE());
    }
  }, [search]);


  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioDRE()}
        onSubmit={async values => {
          await GetRelDRE(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            // {
            //   children: 'Exportar PDF',
            //   onClick: getPDF,
            //   variant: 'rosa',
            // },
          ]}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <Box mb={7} gridColumn={gridColumn.gc12} className="card">
          <SecaoCadastro titulo={'DRE Gerencial'} />
          <TableContainer maxWidth={'100%'} whiteSpace={'revert'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  {meses.map((mes) => (
                    <Th key={mes}>{mes}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {relDRE.map((item, index) => (
                  <Tr key={index}>
                    <Td
                      className={
                        item.Grupo.IsPai
                          ? 'font-weight-bold table-active'
                          : 'font-weight-normal pl-5'
                      }
                    >
                      {item.Grupo.Nome}
                    </Td>
                    {item.Valor.map((valor, i) => (
                      <Td
                        key={i}
                        className={
                          item.Grupo.IsPai
                            ? 'font-weight-bold table-active'
                            : 'font-weight-normal'
                        }
                      >
                        {formataDinheiro(valor)}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="Ano"
        id="Ano"
        name="Ano"
        component={InputNumInt}
        gridColumn={gridColumn.gc6}
      />
    </Filtros>
  );
}
export default RelDRE;
