import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Equipamentos.css';

function EditarEquipamentos() {
  const navigate = useNavigate();
  const [equipamentos, setEquipamentos] = useState({
    Nome: '',
    NumeroSerie: '',
    Marca: '',
    Modelo: '',
    Observacoes: '',
    Id: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetEquipamentos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditEquipamentos/Get', payload);
      if (res.status === 200) {
        let data = res.data;

        console.log(data);
        setEquipamentos(data.Equipamentos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetEquipamentos();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditEquipamentos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={equipamentos}
          validationSchema={yup.object({})}
          onSubmit={async values => {
            setEquipamentos(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();
  let titulo = `${formik.values.Id !== '' ? 'Editar' : 'Novo'} Equipamento`;

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Equipamentos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Equipamentos', path: '/Equipamentos/Listar' },
          { nome: titulo },
        ]}
      />
      <Card
        titulo={
          formik.values.Id == null ? 'Editar Equipamento' : 'Novo Equipamento'
        }
      >
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Número de Série"
          id="NumeroSerie"
          name="NumeroSerie"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="NumeroSerie"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="Marca"
          id="Marca"
          name="Marca"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Modelo"
          id="Modelo"
          name="Modelo"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarEquipamentos;
