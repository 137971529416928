import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL, estados } from '../../utils/utils';
import BarraBotoes from './../../components/BarraBotoes/BarraBotoes';
import Card from './../../components/Card/Card';
import InputFormik from './../../components/Inputs/InputFormik/InputFormik';
import './Empresas.css';
import { ufs, optionsTipoAmbiente, valor_inicial } from './utils';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';

function EditarEmpresas() {
  const navigate = useNavigate();

  const optionsDeposito = ['Selecione', 'Estoque Empresa'];
  const optionsRegime = [
    'Simples Nacional',
    'Simples Nacional com Excesso de Sublimite da Receita Bruta',
    'Lucro Real',
    'Lucro Presumido',
  ];
  const optionsAtividade = [
    'Indústria ou Equiparado a Industrial',
    'Prestador de Serviços',
    'Atividade de Comércio',
    'Atividade Financeira',
    'Atividade Imobiliária',
    'Outros',
  ];
  const optionsIncidencia = [
    '1- Regime Não-cumulativo',
    'Regime Cumulativo',
    'Regime Não-cumulativo e Cumulativo',
    'Regime de Caixa',
  ];
  const [empresa, setEmpresa] = useState(valor_inicial);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetEmpresas() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditEmpresas/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        console.log(data);
        setEmpresa(data.Empresa);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetEmpresas();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditEmpresas/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <div>
      <>
        <Alerta
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          alertStatus={alertStatus}
          title={alertTitle}
        />
        <Pagina loading={loading}>
          <FormikPadrao
            enableReinitialize={true}
            initialValues={empresa}
            validationSchema={yup.object({
              //   // Nome: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // TipoConta: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // CodigoBancoCorrespondente: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // EmiteBoletos: yup.boolean('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // Empresa: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
              //   // SaldoInicial: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // Agencia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // DigitoAgencia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // Conta: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
              //   // DigitoConta: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
            })}
            onSubmit={async values => {
              setEmpresa(values);
              await save(JSON.stringify(values, null, 2));
            }}
          >
            <Interno navigate={navigate} />
          </FormikPadrao>
        </Pagina>
      </>
    </div>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    let getCidades = async () => {
      if (!formik.values.CodigoUF || formik.values.CodigoUF.length !== 2) {
        return;
      }
      try {
        const response = await fetch(
          `https://erpsuite-ibge.s3.us-east-2.amazonaws.com/${formik.values.CodigoUF}.json`
        );
        const json = await response.json();
        const formattedCidades = json.map(cidade => ({
          name: cidade.nome,
          id: cidade.id,
        }));
        formattedCidades.unshift({ name: "Selecione", id: "" });
        setCidades(formattedCidades);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getCidades();
  }, [formik.values.CodigoUF]);

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Empresas/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
      />
      <Card
        titulo={formik.values.Id == null ? 'Editar Empresa' : 'Nova Empresa'}
      >
        <InputFormik
          titulo="Ambiente NFe"
          id="TipoAmbiente"
          name="ConfiguracaoFiscal.TipoAmbiente"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsTipoAmbiente}
        />

        <InputFormik
          titulo="Empresa padrão?"
          id="Padrao"
          name="Padrao"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Nome"
          id="NomeFantasia"
          name="NomeFantasia"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Razão Social"
          id="RazaoSocial"
          name="RazaoSocial"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="CNPJ"
          id="CNPJ"
          name="CNPJ"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="IE"
          id="IE"
          name="IE"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="IM"
          id="IM"
          name="IM"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="CNAE"
          id="CNAE"
          name="CNAE"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="CEP"
          id="CEP"
          name="CEP"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Rua"
          id="Logradouro"
          name="Logradouro"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Número"
          id="LogradouroNumero"
          name="LogradouroNumero"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Complemento"
          id="LogradouroComplemento"
          name="LogradouroComplemento"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Bairro"
          id="Bairro"
          name="Bairro"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Código IBGE"
          id="CodigoMunicipio"
          name="CodigoMunicipio"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Estado"
          id="CodigoUF"
          name="CodigoUF"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={ufs}
        />

        <InputFormik
          titulo="Cidade"
          id="CodigoMunicipio"
          name="CodigoMunicipio"
          component={InputSelect}
          options={cidades}
          gridColumn={gridColumn.gc4}
        />
        {/*
        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        /> */}
      </Card>
    </>
  );
}

export default EditarEmpresas;
