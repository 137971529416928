import {
    Box,
    Flex,
    Grid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputData from '../../components/Inputs/InputData/InputData';
import InputHorario from '../../components/Inputs/InputHorario/InputHorario';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './OrdensServico.css';

function EditarOrdensServico() {
  const navigate = useNavigate();
  const optionsStatus = [
    'Pendente',
    'Em Andamento',
    'Concluído',
    'Entregue',
    'Cancelada',
  ];
  const optionsPlanOContas = [];
  const optionsCondicoesPagamento = [
    'Dinheiro | Dinheiro | 1 | 1 Meses',
    'Crédito | Cartão de Crédito | 1x | 1 Meses',
  ];
  const optionsFormasPagamento = [
    'Selecione',
    'Dinheiro',
    'Carnê',
    'Cartão de Débito',
    'Cartão de Crédito',
    'Cheque',
    'Múltiplo',
    'Boleto Bancário',
    'PIX',
    'Débito em Conta',
  ];
  const [OS, setOS] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetOS() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditOrdensServico/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setOS(data.Ordem);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetOS();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditOrdensServico/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      Object.keys(OS).length > 0
        ? OS
        : {
            Codigo: '',
            Cliente: '',
            Empresa: '',
            Status: '',
            Deposito: '',
            PlanoConta: '',
            TabelaPreco: '',
            DataCadastro: '',
            DataInicio: '',
            PrevisaoEntrega: '',
            HorarioInicio: '',
            HorarioTermino: '',
            Responsavel: '',
            DataValidade: '',
            DataFaturamento: '',
            Vendedor: '',
            Descricao: '',
            Quantidade: '',
            Vencimento: '',
            FormaPagamento: '',
            Valor: 0,
            DescricaoProblema: '',
            DescricaoServico: '',
            Observacoes: '',
            ObservacoesInternas: '',
            Equipamento: '',
            NumeroSerie: '',
            Marca: '',
            Modelo: '',
            Veiculo: '',
            VeiculoPlaca: '',
            CondicaodePagamentoSTR: '',
          },
    validationSchema: yup.object({
      // Codigo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Cliente: yup.string().required('O campo é obrigatório.').nullable(),
      // Empresa: yup.string().required('O campo é obrigatório.').nullable(),
      // Status: yup.string().required('O campo é obrigatório.').nullable(),
      // Deposito: yup.string().required('O campo é obrigatório.').nullable(),
      // PlanoConta: yup.string().required('O campo é obrigatório.').nullable(),
      // TabelaPreco: yup.date().required('O campo é obrigatório.').nullable(),
      // DataCadastro: yup.date().required('O campo é obrigatório.').nullable(),
      // DataInicio: yup.date().required('O campo é obrigatório.').nullable(),
      // PrevisaoEntrega: yup.date().required('O campo é obrigatório.').nullable(),
      // HorarioInicio: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // HorarioTermino: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Responsavel: yup.string().required('O campo é obrigatório.').nullable(),
      // DataValidade: yup.date().required('O campo é obrigatório.').nullable(),
      // DataFaturamento: yup.date().required('O campo é obrigatório.').nullable(),
      // Vendedor: yup.string().required('O campo é obrigatório.').nullable(),
      // Descricao: yup.string().required('O campo é obrigatório.').nullable(),
      // Quantidade: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Vencimento: yup.date().required('O campo é obrigatório.').nullable(),
      // FormaPagamento: yup.string().required('O campo é obrigatório.').nullable(),
      // Valor: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // DescricaoProblema: yup.string().required('O campo é obrigatório.').nullable(),
      // DescricaoServico: yup.string().required('O campo é obrigatório.').nullable(),
      // Observacoes: yup.string().required('O campo é obrigatório.').nullable(),
      // ObservacoesInternas: yup.string().required('O campo é obrigatório.').nullable(),
      // Equipamento: yup.string().required('O campo é obrigatório.').nullable(),
      // NumeroSerie: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Marca: yup.string().required('O campo é obrigatório.').nullable(),
      // Modelo: yup.string().required('O campo é obrigatório.').nullable(),
      // Veiculo: yup.string().required('O campo é obrigatório.').nullable(),
      // VeiculoPlaca: yup.string().required('O campo é obrigatório.').nullable(),
      // CondicaodePagamentoSTR: yup.string().required('O campo é obrigatório.').nullable(),
    }),
    onSubmit: async values => {
      setOS(values);
      await save(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                {/* <InputSearch placeholder="Procurar por OS" style={{ flex: 1 }} />  */}
                <Botao variant="azul">Importar Excel</Botao>
                <Botao
                  variant="vermelho"
                  onClick={() => navigate('/OrdensServico/Listar')}
                >
                  Cancelar
                </Botao>
                <Botao variant="turquesa" type="submit">
                  Salvar
                </Botao>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="NOVO FORNECEDOR" />
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Código</p>
                      <InputNumInt
                        id="Codigo"
                        name="Codigo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Codigo}
                      />
                      {formik.touched.Codigo && formik.errors.Codigo ? (
                        <div>{formik.errors.Codigo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Cliente</p>
                      <InputTexto
                        id="Cliente"
                        name="Cliente"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Cliente}
                      />
                      {formik.touched.Cliente && formik.errors.Cliente ? (
                        <div>{formik.errors.Cliente}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Empresa</p>
                      <InputTexto
                        id="Empresa"
                        name="Empresa"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Empresa}
                      />
                      {formik.touched.Empresa && formik.errors.Empresa ? (
                        <div>{formik.errors.Empresa}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Status</p>
                      <InputSelect
                        id="Status"
                        name="Status"
                        options={optionsStatus}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Status}
                      />
                      {formik.touched.Status && formik.errors.Status ? (
                        <div>{formik.errors.Status}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Depósito</p>
                      <InputTexto
                        id="Deposito"
                        name="Deposito"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Deposito}
                      />
                      {formik.touched.Deposito && formik.errors.Deposito ? (
                        <div>{formik.errors.Deposito}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Plano Contas</p>
                      <InputSelect
                        id="PlanoConta"
                        name="PlanoConta"
                        options={optionsPlanOContas}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PlanoConta}
                      />
                      {formik.touched.PlanoConta && formik.errors.PlanoConta ? (
                        <div>{formik.errors.PlanoConta}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Tabela de Preços</p>
                      <InputTexto
                        id="TabelaPreco"
                        name="TabelaPreco"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.TabelaPreco}
                      />
                      {formik.touched.TabelaPreco &&
                      formik.errors.TabelaPreco ? (
                        <div>{formik.errors.TabelaPreco}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Cadastro</p>
                      <InputData
                        id="DataCadastro"
                        name="DataCadastro"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DataCadastro}
                      />
                      {formik.touched.DataCadastro &&
                      formik.errors.DataCadastro ? (
                        <div>{formik.errors.DataCadastro}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Início</p>
                      <InputData
                        id="DataInicio"
                        name="DataInicio"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DataInicio}
                      />
                      {formik.touched.DataInicio && formik.errors.DataInicio ? (
                        <div>{formik.errors.DataInicio}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Previsão de Entrega</p>
                      <InputData
                        id="PrevisaoEntrega"
                        name="PrevisaoEntrega"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PrevisaoEntrega}
                      />
                      {formik.touched.PrevisaoEntrega &&
                      formik.errors.PrevisaoEntrega ? (
                        <div>{formik.errors.PrevisaoEntrega}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Horário de Início</p>
                      <InputHorario
                        id="HorarioInicio"
                        name="HorarioInicio"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.HorarioInicio}
                      />
                      {formik.touched.HorarioInicio &&
                      formik.errors.HorarioInicio ? (
                        <div>{formik.errors.HorarioInicio}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Horário de Término</p>
                      <InputHorario
                        id="HorarioTermino"
                        name="HorarioTermino"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.HorarioTermino}
                      />
                      {formik.touched.HorarioTermino &&
                      formik.errors.HorarioTermino ? (
                        <div>{formik.errors.HorarioTermino}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Responsável</p>
                      <InputTexto
                        id="Responsavel"
                        name="Responsavel"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Responsavel}
                      />
                      {formik.touched.Responsavel &&
                      formik.errors.Responsavel ? (
                        <div>{formik.errors.Responsavel}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Validade</p>
                      <InputData
                        id="DataValidade"
                        name="DataValidade"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DataValidade}
                      />
                      {formik.touched.DataValidade &&
                      formik.errors.DataValidade ? (
                        <div>{formik.errors.DataValidade}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Faturamento</p>
                      <InputData
                        id="DataFaturamento"
                        name="DataFaturamento"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DataFaturamento}
                      />
                      {formik.touched.DataFaturamento &&
                      formik.errors.DataFaturamento ? (
                        <div>{formik.errors.DataFaturamento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Vendedor</p>
                      <InputTexto
                        id="Vendedor"
                        name="Vendedor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Vendedor}
                      />
                      {formik.touched.Vendedor && formik.errors.Vendedor ? (
                        <div>{formik.errors.Vendedor}</div>
                      ) : null}
                    </Box>
                    <Box p={4} gridColumn={gridColumn.gc12}>
                      <TableContainer>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>PRODUTO / SERVIÇO</Th>
                              <Th>QUANTIDADE</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr>
                              <Td>
                                <InputTexto />
                              </Td>
                              <Td>
                                <InputNumInt />
                              </Td>
                              <Botao mt={4} variant="laranja">
                                Adicionar
                              </Botao>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="CONDIÇÕES DE PAGAMENTO" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Condição de Pagamento</p>
                      <InputSelect
                        id="CondicaodePagamentoSTR"
                        name="CondicaodePagamentoSTR"
                        options={optionsCondicoesPagamento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CondicaodePagamentoSTR}
                      />
                      {formik.touched.CondicaodePagamentoSTR &&
                      formik.errors.CondicaodePagamentoSTR ? (
                        <div>{formik.errors.CondicaodePagamentoSTR}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Vencimento</p>
                      <InputData
                        id="Vencimento"
                        name="Vencimento"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Vencimento}
                      />
                      {formik.touched.Vencimento && formik.errors.Vencimento ? (
                        <div>{formik.errors.Vencimento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Forma de Pagamento</p>
                      <InputSelect
                        id="FormaPagamento"
                        name="FormaPagamento"
                        options={optionsFormasPagamento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FormaPagamento}
                      />
                      {formik.touched.FormaPagamento &&
                      formik.errors.FormaPagamento ? (
                        <div>{formik.errors.FormaPagamento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Valor</p>
                      <InputNumInt
                        id="Valor"
                        name="Valor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Valor}
                      />
                      {formik.touched.Valor && formik.errors.Valor ? (
                        <div>{formik.errors.Valor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Total de parcelas:</p>
                      <p>R${}</p>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES ADICIONAIS" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Descrição do Problema</p>
                      <InputTextArea
                        id="DescricaoProblema"
                        name="DescricaoProblema"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DescricaoProblema}
                      />
                      {formik.touched.DescricaoProblema &&
                      formik.errors.DescricaoProblema ? (
                        <div>{formik.errors.DescricaoProblema}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Descrição do Serviço</p>
                      <InputTextArea
                        id="DescricaoServico"
                        name="DescricaoServico"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DescricaoServico}
                      />
                      {formik.touched.DescricaoServico &&
                      formik.errors.DescricaoServico ? (
                        <div>{formik.errors.DescricaoServico}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Observações</p>
                      <InputTextArea
                        id="Observacoes"
                        name="Observacoes"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Observacoes}
                      />
                      {formik.touched.Observacoes &&
                      formik.errors.Observacoes ? (
                        <div>{formik.errors.Observacoes}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Observações Internas (não visível para o cliente)</p>
                      <InputTextArea
                        id="ObservacoesInternas"
                        name="ObservacoesInternas"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ObservacoesInternas}
                      />
                      {formik.touched.ObservacoesInternas &&
                      formik.errors.ObservacoesInternas ? (
                        <div>{formik.errors.ObservacoesInternas}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES DE EQUIPAMENTOS" />
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Equipamento</p>
                      <InputTexto
                        id="Equipamento"
                        name="Equipamento"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Equipamento}
                      />
                      {formik.touched.Equipamento &&
                      formik.errors.Equipamento ? (
                        <div>{formik.errors.Equipamento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Número de Série</p>
                      <InputNumInt
                        id="NumeroSerie"
                        name="NumeroSerie"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NumeroSerie}
                      />
                      {formik.touched.NumeroSerie &&
                      formik.errors.NumeroSerie ? (
                        <div>{formik.errors.NumeroSerie}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Marca</p>
                      <InputTexto
                        id="Marca"
                        name="Marca"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Marca}
                      />
                      {formik.touched.Marca && formik.errors.Marca ? (
                        <div>{formik.errors.Marca}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Modelo</p>
                      <InputTexto
                        id="Modelo"
                        name="Modelo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Modelo}
                      />
                      {formik.touched.Modelo && formik.errors.Modelo ? (
                        <div>{formik.errors.Modelo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Veículo</p>
                      <InputTexto
                        id="Veiculo"
                        name="Veiculo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Veiculo}
                      />
                      {formik.touched.Veiculo && formik.errors.Veiculo ? (
                        <div>{formik.errors.Veiculo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Placa</p>
                      <InputTexto
                        id="VeiculoPlaca"
                        name="VeiculoPlaca"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.VeiculoPlaca}
                      />
                      {formik.touched.VeiculoPlaca &&
                      formik.errors.VeiculoPlaca ? (
                        <div>{formik.errors.VeiculoPlaca}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="ARQUIVOS" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>
                        Para adicionar arquivos, primeiro salve a ordem de
                        serviço
                      </p>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

export default EditarOrdensServico;
