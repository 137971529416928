let produtos = [
    {
        produto: '11111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 100,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
    {
        produto: '911111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 0,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
    {
        produto: '911111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 0,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
    {
        produto: '911111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 0,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
    {
        produto: '911111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 0,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
    {
        produto: '911111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 0,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
    {
        produto: '911111121 | Camiseta de Praia | Cor: Azul | Tamanho: P',
        quantidade: 1,
        peso: 0,
        valorUnitario: 129.9,
        subtotal: 129.9,
    },
    {
        produto: '14 | FILTRO CABINE PALIO/WEEK/IDEIA/LINEA',
        quantidade: 3,
        peso: 0,
        valorUnitario: 8.5,
        subtotal: 25.5,
    },
];

export const getProdutosTeste = () => {
    return produtos;
}