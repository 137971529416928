import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './CategoriasVendas.css';

function EditarCategoriasVendas() {
  const navigate = useNavigate();
  const [categoriasVendas, setCategoriasVendas] = useState({
    Nome: '',
    EhOperacao: true,
    CanChange: true,
    Id: '',
  });

  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetCategoriasVendas() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditCategoriaVenda/Get', payload);

      if (res.status === 200) {
        let data = res.data;
        setCategoriasVendas(data.CategoriaVenda);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetCategoriasVendas();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditCategoriaVenda/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={categoriasVendas}
          validationSchema={yup.object({
            // Nome: yup.string().required("O campo é obrigatório").nullable(),
            //   EhOperacao: yup.boolean().required("O campo é obrigatório").nullable(),
          })}
          onSubmit={async values => {
            setCategoriasVendas(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();
  let titulo = `${
    formik.values.Id !== '' ? 'Editar' : 'Nova'
  } Categoria de Venda`;

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/CategoriasVendas/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Categorias de Vendas', path: '/CategoriasVendas/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo="NOVA CATEGORIA DE VENDAS">
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="É Operação Fiscal?"
          id="EhOperacao"
          name="EhOperacao"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarCategoriasVendas;
