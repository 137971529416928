import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import PesquisaProdutos from '../../components/PesquisaProdutos/PesquisaProdutos';
import TabelaInterna from '../../components/Tabela/TabelaInterna';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import './Pedidos.css';

function EditarPedidos() {
  const navigate = useNavigate();
  const idOS = window.location.href.split("?os=")[1];

  const [pedido, setPedido] = useState({
    Codigo: 95770157,
    Cliente: '',
    ClienteId: '',
    Empresa: '33204005000195 | ERP Suite',
    EmpresaId: '603f8f4f5e781113602e802f',
    Deposito: 'Estoque da Loja do Pedro',
    DepositoId: '60fea6cfbdeb0b10381be9c3',
    FormaPagamento: 'Dinheiro',
    PlanoConta: '',
    PlanoContaId: '',
    DataCadastro: '2023-03-21',
    Vendedor: '',
    VendedorId: '',
    Status: 'Orcamento',
    DataValidade: '2023-03-26',
    Email: '',
    NumeroNFe: '',
    MeioEnvio: 'Outro',
    FormaEnvio: 'PAC',
    Transportadora: '',
    TransportadoraId: '',
    DataEnvio: '0001-01-01',
    TotalFrete: 0,
    PrevisaoEntrega: '0001-01-01',
    CodigoRastreio: '',
    CEP: '',
    RuaEndereco: '',
    NumeroEndereco: '',
    ComplementoEndereco: '',
    BairroEndereco: '',
    CidadeEndereco: '',
    CodigoCidadeEndereco: '',
    UFEndereco: '',
    CodigoUFEndereco: '',
    PaisEndereco: 'Brasil',
    CodigoPaisEndereco: '1058',
    Observacoes: '',
    TabelaPreco: '',
    TabelaPrecoId: '',
    VendaCategoria: '',
    VendaCategoriaId: '',
    Situacao: '',
    Lancado: false,
    DataFaturamento: '0001-01-01',
    ModoDesconto: 'Valor',
    ModoComissao: 'Valor',
    Desconto: 0,
    FreteContaCliente: false,
    OutrasDespesas: 0,
    DescontoDinheiro: 0,
    DescontoPercentual: 0,
    ValorImpostos: 0,
    ValorComissao: 0,
    ValorSemImpostos: 0,
    ValorFinal: 0,
    Alteracao: '0001-01-01',
    AlteradoPor: '',
    ValorFrete: 0,
    ValorSeguro: 0,
    ValorComissaoRepresentacao: 0,
    ValorTotal: 0,
    ValorTotalComFrete: 0,
    SubTotalProdutos: 0,
    ValorTotalNota: 0,
    ValorFaturado: 0,
    ICMSBaseCalculo: 0,
    ICMSValor: 0,
    ICMSBaseCalculoST: 0,
    ICMSValorST: 0,
    ICMSDescontoZonaFranca: 0,
    ICMSValorDiferencialAliquota: 0,
    COFINSValor: 0,
    COFINSBaseCalculo: 0,
    COFINSDescontoZonaFranca: 0,
    COFINSValorST: 0,
    COFINSBaseCalculoST: 0,
    PISValor: 0,
    PISBaseCalculo: 0,
    PISDescontoZonaFranca: 0,
    PISValorST: 0,
    PISBaseCalculoST: 0,
    ISSQNBase: 0,
    ISSQNValor: 0,
    ISSQNValorST: 0,
    IRRFValor: 0,
    CSLLValor: 0,
    IPIBaseDeCalculo: 0,
    IPIValor: 0,
    INSSValor: 0,
    PesoTotalKg: 0,
    Condicao: '',
    CondicaoPagamento: 0,
    FormaPagamentoSTR: '',
    StatusSTR: '',
    SituacaoSTR: '',
    Origem: 'Sistema',
    IdCaixaPDV: '',
    RetiradaLocal: false,
    IdCondicaoParcelas: '',
    Parcelas: '',
    CondicaodePagamentoSTR: '',
    Id: '',
  });

  const [loading, setLoading] = useState(true);
  const [totalPedido, setTotalPedidos] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetPedidos() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditPedidos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        let pedido = data.Pedido;
        pedido.Produtos = data.Produtos ? data.Produtos : [];
        setPedido(pedido);
        const valores = [data.Pedido.ValorTotalComFrete];
        let total = 0;
        valores.map(valor => (total += valor));
        setTotalPedidos(total);

        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetPedidos();
  }, [id, alertTitle, alertStatus]);

  let save = async (pedido, produtos) => {
    setLoading(true);
    let payload = {
      data: JSON.stringify(pedido, null, 2),
      arg: JSON.stringify(produtos, null, 2),
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditPedidos/Save', payload);
    let data = res.data;
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      setTimeout(() => {
        navigate('/Pedidos/Editar/' + data.ItemID);
      }, 500);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
    setLoading(false);
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={pedido}
          validationSchema={yup.object({})}
          onSubmit={async values => {
            setPedido(values);
            const produtos = values.Produtos;
            const pedido = values;
            delete pedido.Produtos;
            await save(pedido, produtos);
          }}
        >
          <Interno navigate={navigate} idOS={idOS} setAlertStatus={setAlertStatus} setAlertTitle={setAlertTitle} onOpen={onOpen} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, idOS, setAlertStatus, setAlertTitle, onOpen }) {
  const { values, setFieldValue } = useFormikContext();

  const opcoesSituacao = [
    { value: '', label: 'Selecione' },
    { value: 'Pendente', label: 'Pendente' },
    { value: 'EmAndamento', label: 'Em Andamento' },
    { value: 'Concluido', label: 'Concluído' },
    { value: 'Entregue', label: 'Entregue' },
  ];
  const opcoesStatus = [
    { value: 'Orcamento', label: 'Orçamento' },
    { value: 'Pedido', label: 'Pedido' },
    { value: 'PedidoFaturado', label: 'Pedido Faturado' },
    { value: 'PedidoCancelado', label: 'Pedido Cancelado' },
  ];

  async function handleAddProduto(nomeProduto) {
    let payload = {
      database: localStorage.getItem('database'),
      data: nomeProduto,
      arg: 1,
    };
    let res = await api.post('/ProdutoTracker/Get', payload);
    if (res.status === 200) {
      let data = res.data;
      addProdutosCarrinho(data);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function addProdutosCarrinho(produto) {
    const produtos = values.Produtos;
    const pedido = values;
    delete pedido.Produtos;
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(produtos, null, 2),
      arg: JSON.stringify(pedido, null, 2),
      arg2: JSON.stringify(produto, null, 2),
    };

    let res = await api.post('/EditPedidosProdutos/AddProduto', payload);
    if (res.status === 200) {
      let data = res.data;
      let produtosPedido = data.Produtos;
      setFieldValue('Produtos', produtosPedido);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  function removeProdutoCarrinho(produto) {
    var produtos = values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Descricao === produto.Descricao) {
        produtos.splice(i, 1);
        break;
      }
    }
    setFieldValue('Produtos', produtos);
  }

  async function enviarOrcamento() {
    let payload = {
      database: localStorage.getItem('database'),
      data: values.Id,
      arg: idOS,
    };
    let res = await api.post('/EditPedidos/EnviarOrcamento', payload);
    let data = res.data;
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }


  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Pedidos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Enviar por email',
            visible: values.Status === 'Orcamento' || values.Status === 'Pedido' && values.Id !== null,
            onClick: () => enviarOrcamento(),
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Faturar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Duplicar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Baixar PDF',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Emitir NF-e',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Pedidos', path: '/Pedidos/Listar' },
          { nome: 'Novo Pedido' },
        ]}
      />

      <Card titulo="NOVO PEDIDO">
        <InputFormik
          titulo="Código"
          id="Codigo"
          name="Codigo"
          component={InputNumInt}
          gridColumn={gridColumn.gc2}
        />
        <InputFormik
          titulo="Origem"
          id="Origem"
          name="Origem"
          component={InputTexto}
          gridColumn={gridColumn.gc2}
          disabled
        />
        <InputFormik
          titulo="Cliente"
          id="Cliente"
          name="Cliente"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Cliente"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Estoque"
          id="Deposito"
          name="Deposito"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Deposito"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Tabela de Preços"
          id="TabelaPreco"
          name="TabelaPreco"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="TabelasPreco"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Plano de Contas"
          id="PlanoConta"
          name="PlanoConta"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="PlanosContas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Data de Cadastro"
          id="DataCadastro"
          name="DataCadastro"
          local={true}
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Situação"
          id="Situcao"
          name="Situcao"
          component={InputSelect}
          options={opcoesSituacao}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Status"
          id="Status"
          name="Status"
          component={InputSelect}
          options={opcoesStatus}
          gridColumn={gridColumn.gc4}
          disabled={
            values.Status !== 'Orcamento' &&
            values.Status !== 'Pedido'
          }
        />
        <InputFormik
          titulo="Número NFe"
          id="NumeroNFe"
          name="NumeroNFe"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Data de Validade"
          id="DataValidade"
          name="DataValidade"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo="Produtos">
        <PesquisaProdutos funcao={handleAddProduto} />
        <TabelaInterna
          nomeLista="Produtos"
          columns={[
            {
              placeholder: 'Produto',
              name: 'Descricao',
              type: 'text',
            },
            {
              placeholder: 'Quantidade',
              name: 'Quantidade',
              type: 'number',
              editavel: true,
            },
            {
              placeholder: 'Peso',
              name: 'PesoKG',
              type: 'float',
            },
            {
              placeholder: 'Valor (R$)',
              name: 'ValorUnitario',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'SubTotal (R$)',
              name: 'ValorTotal',
              type: 'money',
            },
          ]}
          hiddenColumns={[
            {
              placeholder: 'Desconto (R$)',
              name: 'DescontoUnitario',
              type: 'money',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Saldo em Estoque',
              name: 'SaldoEstoque',
              type: 'float',
              visible: true,
              editavel: true,
            },
          ]}
          showDelete={true}
          handleDelete={removeProdutoCarrinho}
        />
      </Card>
    </>
  );
}

export default EditarPedidos;
