import { GetDatasAjustadas } from './utils';

const opcaoPadraoFiltro = 'Personalizado';

const valueToString = valor => {
  if (valor === null || valor === undefined) return '';
  if (typeof valor === 'string') return valor.toLowerCase();
  if (typeof valor === 'number') return valor.toString().replace('.', ',');
  else return valor.toString().toLowerCase();
};

const filtrar = (item, termo) => {
  return Object.keys(item).some(
    key =>
      item[key] && valueToString(item[key]).search(termo.toLowerCase()) !== -1
  );
};

//Telas de relatórios

const getFiltroPadraoRelatorioCheques = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Cliente: '',
    Empresa: '',
    NumeroCheque: 0,
    Situacao: '',
    Valor: 0,
    TipoData: 'emissao',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioCentroCustos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    TipoData: 'emissao',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioFluxoCaixa = () => {
  const filtro_padrao = {
    Ano: new Date().getFullYear(),
    Empresa: '',
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioDRE = () => {
  const filtro_padrao = {
    Ano: new Date().getFullYear(),
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioEntregaPedidos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: 0,
    Empresa: '',
    Cliente: '',
    Situacao: '',
    Transportadora: '',
    ApenasFaturados: false,
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioValidadePedidos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: 0,
    Empresa: '',
    Cliente: '',
    ValorMinimo: 0,
    ValorMaximo: 0,
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioOrcamentos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: 0,
    Empresa: '',
    Cliente: '',
    ValorMinimo: 0,
    ValorMaximo: 0,
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioEstoque = () => {
  const filtro_padrao = {
    Produto: '',
    Estoque: '',
    Empresa: '',
    ApenasProdutosComSaldo: false,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioValorEstoque = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    Estoque: '',
    Produto: '',
    Lote: '',
    ConsiderarValidade: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioFaturamento = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: '',
    Empresa: '',
    Cliente: '',
    Situacao: '',
    Transportadora: '',
    ApenasFaturados: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioFaturamentoVendas = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: 0,
    Empresa: '',
    Cliente: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioFechamentoPDV = email => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    DataAbertura: dataFiltro.dataFinal,
    Email: email,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioFluxoBancario = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    ContaBancaria: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioInadimplentes = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    Cliente: '',
    NumeroLancamento: 0,
    Pedido: '',
    ValorMinimo: 0,
    ValorMaximo: 0,
    Data: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioInventario = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    Estoque: '',
    Produto: '',
    Data: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioOrdensProducao = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: '',
    Status: '',
    TipoData: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioOrdensServico = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Servico: '',
    Cliente: '',
    Situacao: '',
    TipoData: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioPrevisaoCaixa = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    FormaPagamento: '',
    Cliente: '',
    TipoData: '',
    PlanoConta: '',
    CentroCustos: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioSinteseFinanceira = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    TipoData: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioLancamentos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Cliente: '',
    Empresa: '',
    Banco: '',
    TipoLancamento: '',
    Pago: '',
    PlanoConta: '',
    CentroCustos: '',
    TipoData: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioLancamentosNaoPagos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Cliente: '',
    Empresa: '',
    Banco: '',
    TipoLancamento: '',
    TipoData: 'emissao',
    PlanoConta: '',
    Pago: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioLancamentosVencidos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Cliente: '',
    Empresa: '',
    Banco: '',
    TipoLancamento: '',
    TipoData: 'emissao',
    PlanoConta: '',
    Pago: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioLucroPedidos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: 0,
    Cliente: '',
    Empresa: '',
    ApenasCompletamentePagos: false,
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoRelatorioNotasCompra = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Empresa: '',
    Fornecedor: '',
    Transportadora: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

//Telas de Listagem

const getFiltroPadraoLancamentos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: 0,
    Cliente: '',
    TipoLancamento: '',
    TipoData: '',
    Pago: '',
    Empresa: '',
    CategoriaLancamento: '',
    Cancelado: '',
    Banco: '',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoNFe = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Emissor: '',
    Destinatario: '',
    Codigo: 0,
    Valor: 0,
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoBoletos = () => {
  const filtro_padrao = {
    Cliente: '',
    Empresa: '',
    RemessaEnviada: false,
  };

  return filtro_padrao;
};

const getFiltroPadraoPedidos = () => {
  var dataFiltro = GetDatasAjustadas(opcaoPadraoFiltro);

  const filtro_padrao = {
    Codigo: '',
    Cliente: '',
    Empresa: '',
    Vendedor: '',
    Status: '',
    TipoData: 'cadastro',
    DataInicial: dataFiltro.dataInicial,
    DataFinal: dataFiltro.dataFinal,
  };

  return filtro_padrao;
};

const getFiltroPadraoTranferenciaBancaria = () => {
  const filtro_padrao = {
    Codigo: '',
    EmpresaOrigem: '',
    EmpresaDestino: '',
    BancoOrigem: '',
    BancoDestino: '',
    Data: '',
  };

  return filtro_padrao;
};

export {
  filtrar,
  getFiltroPadraoLancamentos,
  getFiltroPadraoPedidos,
  getFiltroPadraoRelatorioCentroCustos,
  getFiltroPadraoRelatorioCheques,
  getFiltroPadraoRelatorioEntregaPedidos,
  getFiltroPadraoRelatorioEstoque,
  getFiltroPadraoRelatorioFaturamento,
  getFiltroPadraoRelatorioFaturamentoVendas,
  getFiltroPadraoRelatorioFechamentoPDV,
  getFiltroPadraoRelatorioFluxoBancario,
  getFiltroPadraoRelatorioInadimplentes,
  getFiltroPadraoRelatorioInventario,
  getFiltroPadraoRelatorioLancamentos,
  getFiltroPadraoRelatorioLancamentosNaoPagos,
  getFiltroPadraoRelatorioLancamentosVencidos,
  getFiltroPadraoRelatorioLucroPedidos,
  getFiltroPadraoRelatorioNotasCompra,
  getFiltroPadraoTranferenciaBancaria,
  getFiltroPadraoRelatorioValidadePedidos,
  getFiltroPadraoRelatorioFluxoCaixa,
  getFiltroPadraoRelatorioValorEstoque,
  getFiltroPadraoRelatorioPrevisaoCaixa,
  getFiltroPadraoRelatorioSinteseFinanceira,
  getFiltroPadraoRelatorioOrdensProducao,
  getFiltroPadraoRelatorioOrdensServico,
  getFiltroPadraoRelatorioOrcamentos,
  getFiltroPadraoNFe,
  getFiltroPadraoBoletos,
  getFiltroPadraoRelatorioDRE
};
