import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL, estados } from '../../utils/utils';
import './NFCe.css';

function EditarNFCe() {
  const navigate = useNavigate();
  const optionsFinalidade = [
    'Normal',
    'NF-e Complementar',
    'Ajuste',
    'Devolução/Retorno',
  ];
  const optionsTipoNEf = ['Saída', 'Entrada'];
  const optionsCSOSN = [
    '101- Tributada pelo Simples Nacional com permissão de crédito de ICMS',
    '102- Tributação pelo Simples Nacional sem permissão de crédito',
    '103- Insenção de ICMS no Simples Nacional na faixa de receita bruta',
    '201- Tributada pelo Simples Nacional com permissão de crédito e cobrança do ICMS por ST',
    '202- Tributada pelo Simples Nacional sem permissão de crédito e cobrança do ICMS por ST',
    '203- Insenção do ICMS no Simples Nacional para faixa de receita bruta e cobrança de ICMS por ST',
    '300- Imune de ICMS',
    '400- Não Tributada pelo Simples Nacional',
    '500- ICMS cobrado anteriormente por ST ou por antecipação',
    '900- Outros',
  ];
  const optionsIndicadorIE = [
    'Isento',
    'Contribuinte ICMS',
    'Não Contribuinte',
  ];
  const optionsCidades = [];
  const optionsFormaPagamento = [
    'Dinheiro',
    'Boleto',
    'Cheque',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Crédito Loja',
    'Vale Alimentação',
    'Vale Refeição',
    'Vale Presente',
    'Vale Combustível',
    'Duplicata Mercantil',
    'Depósito Bancário',
    'PIX',
    'Programa de Fidelidade',
    'Sem pagamento',
    'Transferência Bancária',
  ];
  const optionsTipoPagamento = ['À Vista', 'À Prazo', 'Outro'];
  const [NFCe, setNFCe] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetNFCe() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditNFCe/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setNFCe(data.NFCe);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetNFCe();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditNFCe/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      Object.keys(NFCe).length > 0
        ? NFCe
        : {
            NaturezaDaOperacao: '',
            FinalidadeNF: '',
            SerieNFe: '',
            NumeroNFe: '',
            TipoNF: '',
            Emissor: '',
            CFOP: '',
            CSOSN: '',
            Cliente: '',
            NumeroDocumento: '',
            IE: '',
            IndicadorIE: '',
            CEP: '',
            Logradouro: '',
            Numero: '',
            Bairro: '',
            CodigoIBGEMunicipio: '',
            CodigoIBGEUF: '',
            ConsumidorFinal: '',
            PessoaFisica: '',
            FormaPagamento: '',
            Transportadora: '',
            Telefone: '',
            Email: '',
            RNTC: '',
            PlacaVeiculo: '',
            SomarFrete: '',
            produtoAtual: '',
            Nome: '',
            Tipo: '',
            Observacoes: '',
          },
    validationSchema: yup.object({
      // NaturezaDaOperacao: yup.string().required('O campo é obrigatório.').nullable(),
      // FinalidadeNF: yup.string().required('O campo é obrigatório.').nullable(),
      // SerieNFe: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // NumeroNFe: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // TipoNF: yup.string().required('O campo é obrigatório.').nullable(),
      // Emissor: yup.string().required('O campo é obrigatório.').nullable(),
      // CFOP: yup.string().required('O campo é obrigatório.').nullable(),
      // CSOSN: yup.string().required('O campo é obrigatório.').nullable(),
      // Cliente: yup.string().required('O campo é obrigatório.').nullable(),
      // NumeroDocumento: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // IE: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // IndicadorIE: yup.string().required('O campo é obrigatório.').nullable(),
      // CEP: yup.string().required('O campo é obrigatório.').nullable(),
      // Logradouro: yup.string().required('O campo é obrigatório.').nullable(),
      // Numero: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Bairro: yup.string().required('O campo é obrigatório.').nullable(),
      // CodigoIBGEMunicipio: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // CodigoIBGEUF: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ConsumidorFinal: yup.boolean().required('O campo é obrigatório.').nullable(),
      // PessoaFisica: yup.boolean().required('O campo é obrigatório.').nullable(),
      // FormaPagamento: yup.string().required('O campo é obrigatório.').nullable(),
      // Transportadora: yup.string().required('O campo é obrigatório.').nullable(),
      // Telefone: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Email: yup.string().email('Insira um email válido').required('O campo é obrigatório.').nullable(),
      // RNTC: yup.string().required('O campo é obrigatório.').nullable(),
      // PlacaVeiculo: yup.string().required('O campo é obrigatório.').nullable(),
      // SomarFrete: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // produtoAtual: yup.string().required('O campo é obrigatório.').nullable(),
      // Nome: yup.string().required('O campo é obrigatório.').nullable(),
      // Tipo: yup.string().required('O campo é obrigatório.').nullable(),
      // Observacoes: yup.string().required('O campo é obrigatório.').nullable(),
    }),
    onSubmit: async values => {
      setNFCe(values);
      await save(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                {/* <InputSearch placeholder="Procurar por NFCe" style={{ flex: 1 }} />  */}
                <Botao variant="azul">Importar Excel</Botao>
                <Botao
                  variant="vermelho"
                  onClick={() => navigate('/NFCe/Listar')}
                >
                  Cancelar
                </Botao>
                <Botao variant="turquesa" type="submit">
                  Salvar
                </Botao>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="NOVA NFCe" />
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Natureza da Operação</p>
                      <InputTexto
                        id="NaturezaDaOperacao"
                        name="NaturezaDaOperacao"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NaturezaDaOperacao}
                      />
                      {formik.touched.NaturezaDaOperacao &&
                      formik.errors.NaturezaDaOperacao ? (
                        <div>{formik.errors.NaturezaDaOperacao}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Finalidade</p>
                      <InputSelect
                        id="FinalidadeNF"
                        name="FinalidadeNF"
                        options={optionsFinalidade}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FinalidadeNF}
                      />
                      {formik.touched.FinalidadeNF &&
                      formik.errors.FinalidadeNF ? (
                        <div>{formik.errors.FinalidadeNF}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Série</p>
                      <InputNumInt
                        id="SerieNFe"
                        name="SerieNFe"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.SerieNFe}
                      />
                      {formik.touched.SerieNFe && formik.errors.SerieNFe ? (
                        <div>{formik.errors.SerieNFe}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Número</p>
                      <InputNumInt
                        id="NumeroNFe"
                        name="NumeroNFe"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NumeroNFe}
                      />
                      {formik.touched.NumeroNFe && formik.errors.NumeroNFe ? (
                        <div>{formik.errors.NumeroNFe}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Tipo</p>
                      <InputSelect
                        id="TipoNF"
                        name="TipoNF"
                        options={optionsTipoNEf}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.TipoNF}
                      />
                      {formik.touched.TipoNF && formik.errors.TipoNF ? (
                        <div>{formik.errors.TipoNF}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Empresa</p>
                      <InputAutoComplete
                        id="Emissor"
                        name="Emissor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Emissor}
                        path="Empresa"
                        onSourceSelect={formik.setFieldValue}
                        database={localStorage.getItem('database')}
                      />
                      {formik.touched.Emissor && formik.errors.Emissor ? (
                        <div>{formik.errors.Emissor}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>CFOP</p>
                      <InputAutoComplete
                        id="CFOP"
                        name="CFOP"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CFOP}
                        path="CFOP"
                        onSourceSelect={formik.setFieldValue}
                        database={localStorage.getItem('database')}
                      />
                      {formik.touched.CFOP && formik.errors.CFOP ? (
                        <div>{formik.errors.CFOP}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>CSOSN</p>
                      <InputSelect
                        id="CSOSN"
                        name="CSOSN"
                        options={optionsCSOSN}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CSOSN}
                      />
                      {formik.touched.CSOSN && formik.errors.CSOSN ? (
                        <div>{formik.errors.CSOSN}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Cliente</p>
                      <InputAutoComplete
                        id="Cliente"
                        name="Cliente"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Cliente}
                        path="ClienteFornecedor"
                        onSourceSelect={formik.setFieldValue}
                        database={localStorage.getItem('database')}
                      />
                      {formik.touched.Cliente && formik.errors.Cliente ? (
                        <div>{formik.errors.Cliente}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>CPF/CNPJ</p>
                      <InputNumInt
                        id="NumeroDocumento"
                        name="NumeroDocumento"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NumeroDocumento}
                      />
                      {formik.touched.NumeroDocumento &&
                      formik.errors.NumeroDocumento ? (
                        <div>{formik.errors.NumeroDocumento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Insc. Estadual</p>
                      <InputNumInt
                        id="IE"
                        name="IE"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.IE}
                      />
                      {formik.touched.IE && formik.errors.IE ? (
                        <div>{formik.errors.IE}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Indicador IE</p>
                      <InputSelect
                        id="IndicadorIE"
                        name="IndicadorIE"
                        options={optionsIndicadorIE}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.IndicadorIE}
                      />
                      {formik.touched.IndicadorIE &&
                      formik.errors.IndicadorIE ? (
                        <div>{formik.errors.IndicadorIE}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>CEP</p>
                      <InputNumInt
                        id="CEP"
                        name="CEP"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CEP}
                      />
                      {formik.touched.CEP && formik.errors.CEP ? (
                        <div>{formik.errors.CEP}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Rua</p>
                      <InputTexto
                        id="Logradouro"
                        name="Logradouro"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Logradouro}
                      />
                      {formik.touched.Logradouro && formik.errors.Logradouro ? (
                        <div>{formik.errors.Logradouro}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Número</p>
                      <InputNumInt
                        id="Numero"
                        name="Numero"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Numero}
                      />
                      {formik.touched.Numero && formik.errors.Numero ? (
                        <div>{formik.errors.Numero}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Bairro</p>
                      <InputTexto
                        id="Bairro"
                        name="Bairro"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Bairro}
                      />
                      {formik.touched.Bairro && formik.errors.Bairro ? (
                        <div>{formik.errors.Bairro}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Cidade</p>
                      <InputSelect
                        id="CodigoIBGEMunicipio"
                        name="CodigoIBGEMunicipio"
                        options={optionsCidades}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CodigoIBGEMunicipio}
                      />
                      {formik.touched.CodigoIBGEMunicipio &&
                      formik.errors.CodigoIBGEMunicipio ? (
                        <div>{formik.errors.CodigoIBGEMunicipio}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Estado</p>
                      <InputSelect
                        id="CodigoIBGEUF"
                        name="CodigoIBGEUF"
                        options={estados}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CodigoIBGEUF}
                      />
                      {formik.touched.CodigoIBGEUF &&
                      formik.errors.CodigoIBGEUF ? (
                        <div>{formik.errors.CodigoIBGEUF}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Consumidor Final</p>
                      <InputToggle
                        id="ConsumidorFinal"
                        name="ConsumidorFinal"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ConsumidorFinal}
                      />
                      {formik.touched.ConsumidorFinal &&
                      formik.errors.ConsumidorFinal ? (
                        <div>{formik.errors.ConsumidorFinal}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Pessoa Física?</p>
                      <InputToggle
                        id="PessoaFisica"
                        name="PessoaFisica"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PessoaFisica}
                      />
                      {formik.touched.PessoaFisica &&
                      formik.errors.PessoaFisica ? (
                        <div>{formik.errors.PessoaFisica}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES SOBRE O PRODUTO" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Produto</p>
                      <InputAutoComplete
                        id="Nome"
                        name="Nome"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Nome}
                        path="Produto"
                        onSourceSelect={formik.setFieldValue}
                        database={localStorage.getItem('database')}
                      />
                      {formik.touched.Nome && formik.errors.Nome ? (
                        <div>{formik.errors.Nome}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES DE PAGAMENTO" />
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Forma de Pagamento</p>
                      <InputSelect
                        id="FormaPagamento"
                        name="FormaPagamento"
                        options={optionsFormaPagamento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FormaPagamento}
                      />
                      {formik.touched.FormaPagamento &&
                      formik.errors.FormaPagamento ? (
                        <div>{formik.errors.FormaPagamento}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Tipo de pagamento</p>
                      <InputSelect
                        id="Tipo"
                        name="Tipo"
                        options={optionsTipoPagamento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Tipo}
                      />
                      {formik.touched.Tipo && formik.errors.Tipo ? (
                        <div>{formik.errors.Tipo}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES SOBRE A NOTA" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Observações Obrigatórias</p>
                      <InputTextArea
                        id="Observacoes"
                        name="Observacoes"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Observacoes}
                      />
                      {formik.touched.Observacoes &&
                      formik.errors.Observacoes ? (
                        <div>{formik.errors.Observacoes}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <p>Observações Complementares</p>
                      <InputTextArea
                        id="Observacoes"
                        name="Observacoes"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Observacoes}
                      />
                      {formik.touched.Observacoes &&
                      formik.errors.Observacoes ? (
                        <div>{formik.errors.Observacoes}</div>
                      ) : null}
                      <p>
                        * O Valor Aproximado dos Tributos é referente a Lei da
                        Transparência 12.741/2012, que estabelece que ao vender
                        para consumidor final, os valores aproximados dos
                        tributos precisam estar descriminados no documento
                        fiscal. O valor aproximado do campo acima é meramente
                        informativo.
                      </p>
                    </Box>
                    <Box
                      className="notasNFCe"
                      p={2}
                      gridColumn={gridColumn.gc12}
                    >
                      <p>
                        <b>TOTAL DA NOTA</b>
                      </p>
                      <p>
                        <b>R$ </b>
                      </p>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

export default EditarNFCe;
