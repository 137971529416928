import { Input } from '@chakra-ui/react';
import React from 'react';
import './InputNumInt.css';

function InputNumInt({ placeholder, ...rest }) {

  return (
    <Input
      className="inputnumint"
      type="number"
      placeholder={placeholder}
      {...rest}
    />
  );
}

export default InputNumInt;
