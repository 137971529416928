import {
    Box,
    Flex,
    Grid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Botao from '../../components/Botao/Botao';
import Card from '../../components/Card/Card';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './CondicoesPagamento.css';

function EditarCondicoesPagamento() {
  const navigate = useNavigate();
  const optionsPagamento = [
    'Dinheiro',
    'Carnê',
    'Cartão de Débito',
    'Cartão de Crédito',
    'Cheque',
    'Múltiplo',
    'Boleto Bancário',
    'PIX',
    'Débito em Conta',
  ];
  const optionsIntervalo = ['Meses', 'Dias'];
  const [condicoesPagamento, setCondicoesPagamento] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetCondicoesPagamento() {
      if (alertStatus !== 'success' && alertTitle.length >= 0) return;
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditCondicoesPagamento/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setCondicoesPagamento(data.CondicaoPagamento);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetCondicoesPagamento();
  }, [id, alertTitle, alertStatus]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditCondicoesPagamento/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      Object.keys(condicoesPagamento).length > 0
        ? condicoesPagamento
        : {
            Descricao: '',
            TabelaPreco: '',
            TabelaPrecoId: '',
            Inativa: '',
            // Intervalo: '',
            // FormaPagamento: '',
            // QtdParcelas: '',
            // Tipo: '',
            // Juros: '',
            // DescontoTotal: '',
            // DescontoAntecipacao: '',
            // QtdParcelasSemJuros: '',
            ValorInicial: 0,
            ValorFinal: 0,
          },
    // validationSchema: yup.object({
    //   Descricao: yup.string().required('O campo é obrigatório.').nullable(),
    //   TabelaPreco: yup.string().required('O campo é obrigatório.').nullable(),
    //   Inativa: yup.boolean().required('O campo é obrigatório.').nullable(),
    //   Intervalo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
    //   FormaPagamento: yup.string().required('O campo é obrigatório.').nullable(),
    //   QtdParcelas: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
    //   Tipo: yup.string().required('O campo é obrigatório.').nullable(),
    //   Juros: yup.string().required('O campo é obrigatório.').nullable(),
    //   DescontoTotal: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
    //   DescontoAntecipacao: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
    //   QtdParcelasSemJuros: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
    // }),
    onSubmit: async values => {
      setCondicoesPagamento(values);
      await save(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                {/* <InputSearch placeholder="Procurar por condições de pagamento" style={{ flex: 1 }} />  */}
                <Botao
                  variant="vermelho"
                  onClick={() => navigate('/CondicoesPagamento/Listar')}
                >
                  Cancelar
                </Botao>
                <Botao variant="turquesa" type="submit">
                  Salvar
                </Botao>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="NOVA CONDIÇÃO DE PAGAMENTO" />
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>Descrição</p>
                      <InputTexto
                        id="Descricao"
                        name="Descricao"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Descricao}
                      />
                      {formik.touched.Descricao && formik.errors.Descricao ? (
                        <div>{formik.errors.Descricao}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Tabela</p>
                      <InputAutoComplete
                        id="TabelaPreco"
                        name="TabelaPreco"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.TabelaPreco}
                        path="TabelasPreco"
                        onSourceSelect={formik.setFieldValue}
                        database={localStorage.getItem('database')}
                      />
                      {formik.touched.TabelaPreco &&
                      formik.errors.TabelaPreco ? (
                        <div>{formik.errors.TabelaPreco}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Condição Inativa</p>
                      <InputToggle
                        id="Inativa"
                        name="Inativa"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Inativa}
                      />
                      {formik.touched.Inativa && formik.errors.Inativa ? (
                        <div>{formik.errors.Inativa}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="DADOS DA CONDIÇÃO" />
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>Forma de Pagamento</p>
                      <InputSelect
                        id="FormaPagamento"
                        name="FormaPagamento"
                        options={optionsPagamento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.FormaPagamento}
                      />
                      {formik.touched.FormaPagamento &&
                      formik.errors.FormaPagamento ? (
                        <div>{formik.errors.FormaPagamento}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Número de Parcelas</p>
                      <InputNumInt
                        id="QtdParcelas"
                        name="QtdParcelas"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.QtdParcelas}
                      />
                      {formik.touched.QtdParcelas &&
                      formik.errors.QtdParcelas ? (
                        <div>{formik.errors.QtdParcelas}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc2}>
                      <p>Intervalo</p>
                      <InputNumInt
                        id="Intervalo"
                        name="Intervalo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Intervalo}
                      />
                      {formik.touched.Intervalo && formik.errors.Intervalo ? (
                        <div>{formik.errors.Intervalo}</div>
                      ) : null}
                    </Box>
                    <Box mt={7} gridColumn={gridColumn.gc2}>
                      <InputSelect
                        id="Tipo"
                        name="Tipo"
                        options={optionsIntervalo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Tipo}
                      />
                      {formik.touched.Tipo && formik.errors.Tipo ? (
                        <div>{formik.errors.Tipo}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Juros (%)</p>
                      <InputNumInt
                        id="Juros"
                        name="Juros"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Juros}
                      />
                      {formik.touched.Juros && formik.errors.Juros ? (
                        <div>{formik.errors.Juros}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Desconto Máximo (%)</p>
                      <InputNumInt
                        id="DescontoTotal"
                        name="DescontoTotal"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DescontoTotal}
                      />
                      {formik.touched.DescontoTotal &&
                      formik.errors.DescontoTotal ? (
                        <div>{formik.errors.DescontoTotal}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Desconto Antecipação</p>
                      <InputNumInt
                        id="DescontoAntecipacao"
                        name="DescontoAntecipacao"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DescontoAntecipacao}
                      />
                      {formik.touched.DescontoAntecipacao &&
                      formik.errors.DescontoAntecipacao ? (
                        <div>{formik.errors.DescontoAntecipacao}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Parcelas S/ Juros</p>
                      <InputNumInt
                        id="QtdParcelasSemJuros"
                        name="QtdParcelasSemJuros"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.QtdParcelasSemJuros}
                      />
                      {formik.touched.QtdParcelasSemJuros &&
                      formik.errors.QtdParcelasSemJuros ? (
                        <div>{formik.errors.QtdParcelasSemJuros}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <Botao variant="laranja" type="submit">
                        Adicionar
                      </Botao>
                    </Box>
                    <Box
                      className="CondicoesPagamentoCadastradas"
                      gridColumn={gridColumn.gc12}
                    >
                      <TableContainer>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>FORMA DE PAGAMENTO</Th>
                              <Th>PARCELAS</Th>
                              <Th>JUROS</Th>
                              <Th>DESCONTO MÁXIMO</Th>
                              <Th>DESCONTO ANTECIPAÇÃO</Th>
                              <Th></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr className="CondicoesPagamento">
                              <Td>{formik.values.FormaPagamento}</Td>
                              <Td>{formik.values.QtdParcelas}</Td>
                              <Td>{formik.values.Juros}</Td>
                              <Td>{formik.values.DescontoTotal}</Td>
                              <Td>{formik.values.DescontoAntecipacao}</Td>
                              <Td></Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/CondicoesPagamento/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
      />
      <Card
        titulo={formik.values.Id == null ? 'Editar Condição' : 'Nova Condição'}
      >
        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo=""
          id=""
          name=""
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarCondicoesPagamento;
